import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'
import appReducer from './Reducers';
const initialState = {};

const middleware = [thunk];

const persistConfig = {
    key: 'graana',
    storage,
}
export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:graana')
        localStorage.removeItem('graana-token')
        state = initialState;
    }  
    return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const AppStore = createStore(
    persistedReducer,
    initialState,
    compose(
        applyMiddleware(...middleware)
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

let persistor = persistStore(AppStore)
export { AppStore, persistor };