import {EventConstants} from './../Actions/Types'
const reduceConstants = EventConstants
const initialState = {
    all: [],
};

export function EventReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload,
            }

        
        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE:
            let index = state.all.findIndex(event => event.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }


        case reduceConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}