import {LeadConstants} from './../Actions/Types'
const reduceConstants = LeadConstants
const initialState = {
    all: [],
    lead:{},
    task_types:[],
    count: 0,
};

export function LeadReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload.rows,
                count: action.payload.count
            }

        

        case reduceConstants.IMPORT:
            return {
                ...state
            }

        case reduceConstants.SINGLE:
            return {
                ...state,
                lead: action.payload,
            }


        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE:
            let index = state.all.findIndex(lead => lead.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
                // all: [...state.all, action.payload]
            }

        case reduceConstants.TASK_TYPES:
            return{
                ...state,
                task_types: action.payload,
            }
        case reduceConstants.FAILED:
            return {
                ...state
            }

        case reduceConstants.CLEAR:
            return {
                ...state,
                all: [],
                count: 0,
            }
        
        
        default:
            return state

    }
}