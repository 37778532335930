import React, { Component, lazy, Suspense, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { FullLoader } from './../Helpers'
import { BASE_PATH } from './../Config'
import DashboardLayout from './../Layouts/DashboardLayout';
const Login = lazy(() => import(/*webpackChunkName: "auth-login" */ './../Views/Auth/Login'))
const Dashboard = lazy(() => import(/*webpackChunkName: "dashboard" */ './../Views/Dashboard'))
const Reports = lazy(() => import(/*webpackChunkName: "reports" */ './../Views/Reports'))
const Staff = lazy(() => import(/*webpackChunkName: "staff" */ './../Views/Staff'))
const Roles = lazy(() => import(/*webpackChunkName: "staff" */ './../Views/Staff/Roles'))
const Projects = lazy(() => import(/*webpackChunkName: "projects" */ './../Views/Projects'))
const Units = lazy(() => import(/*webpackChunkName: "units" */ './../Views/Projects/Units'))
const Inventory = lazy(() => import(/*webpackChunkName: "inventory" */ './../Views/Inventory'))
const Leads = lazy(() => import(/*webpackChunkName: "leads" */ './../Views/Leads'))
const Tasks = lazy(() => import(/*webpackChunkName: "tasks" */ './../Views/Leads/Tasks'))
const Clients = lazy(() => import(/*webpackChunkName: "tasks" */ './../Views/Clients'))
const Team = lazy(() => import(/*webpackChunkName: "staff" */ './../Views/Team'))
const Events = lazy(() => import(/*webpackChunkName: "events" */ './../Views/Events'))
const Todo = lazy(() => import(/*webpackChunkName: "todo" */ './../Views/Todo'))
const Notes = lazy(() => import(/*webpackChunkName: "notes" */ './../Views/Notes'))
const Emoji = lazy(() => import(/*webpackChunkName: "notes" */ './../Views/Notes/EmojiSlider'))
const Settings = lazy(() => import(/*webpackChunkName: "settings" */ './../Views/Settings'))
const Announcements = lazy(() => import(/*webpackChunkName: "announcements" */ './../Views/Announcements'))
const LeadsCalendar = lazy(() => import(/*webpackChunkName: "calendar" */ './../Views/Calendar'))
const Roster = lazy(() => import(/*webpackChunkName: "roster" */ './../Views/Roster'))

export default class Routes extends Component {
    render() {
        return (
            <Fragment>
                <Router basename={BASE_PATH}>
                    <Switch>
                        <Suspense fallback={<FullLoader/>}>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/settings" component={DashboardLayout(Settings)} />
                            <Route exact path="/" component={DashboardLayout(Dashboard)} />
                            <Route exact path="/team" component={DashboardLayout(Team)} />
                            {/* <Route exact path="/team/roles" component={DashboardLayout(Roles)} /> */}
                            <Route exact path="/projects" component={DashboardLayout(Projects)} />
                            <Route exact path="/projects/:id" component={DashboardLayout(Units)} />

                            <Route exact path="/inventory" component={DashboardLayout(Inventory)} />
                            <Route exact path="/leads" component={DashboardLayout(Leads)} />
                            <Route exact path="/leads/:lead_id" component={DashboardLayout(Leads)} />
                            <Route exact path="/tasks" component={DashboardLayout(Tasks)} />
                            <Route exact path="/clients" component={DashboardLayout(Clients)} />
                            <Route exact path="/organization" component={DashboardLayout(Staff)} />
                            <Route exact path="/organization/roles" component={DashboardLayout(Roles)} />

                            <Route exact path="/reports" component={DashboardLayout(Reports)} />

                            <Route exact path="/events" component={DashboardLayout(Events)} />
                            <Route exact path="/todo" component={DashboardLayout(Todo)} />
                            <Route exact path="/notes" component={DashboardLayout(Notes)} />
                            <Route exact path="/announcements" component={DashboardLayout(Announcements)} />
                            <Route exact path="/calendar" component={DashboardLayout(LeadsCalendar)} />
                            <Route exact path="/roster" component={DashboardLayout(Roster)} />
                            <Route exact path="/emoji" component={DashboardLayout(Emoji)}/>
                            
                        </Suspense>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}