import {ProjectConstants} from './../Actions/Types'
const reduceConstants = ProjectConstants
const initialState = {
    all: [],
    project:{},
    unit_types:[],
    inventory: [],
    project_inventory: [],    
    inventory_count: 0,
};

export function ProjectReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload,
            }

        case reduceConstants.INVENTORY:
            return {
                ...state,
                inventory: action.payload,
            }

        case reduceConstants.INVENTORY_PAGING:
            return{
                ...state,
                inventory: action.payload.rows,
                inventory_count: action.payload.count
            }


        case reduceConstants.PROJECT_INVENTORY:
            return {
                ...state,
                project_inventory: action.payload,
            }

        case reduceConstants.SINGLE:
            return {
                ...state,
                project: action.payload,
            }

        case reduceConstants.UNIT_TYPES:
            return {
                ...state,
                unit_types: action.payload,
            }

        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE:
            let index = state.all.findIndex(project => project.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }

        


        case reduceConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}