import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import Button from 'antd/lib/button'
import Icon from 'antd/lib/icon'
import Avatar from 'antd/lib/avatar'
import {LogoSVG} from './../Config'
import {FullLoader, isEmpty} from './../Helpers'
// import ioClient from "socket.io-client";
import Ability from './../Helpers/Ability'
import {Can} from './../Helpers/AbilityContext'
import Popover from 'antd/lib/popover'
import {Namify} from './../Config'
import {rootReducer} from './../Store/AppStore'
// const socket = ioClient('http://localhost:3030');

export default function (ComposedComponent) {

    class DashboardLayout extends Component {
        constructor(props)
        {
            super(props)
            this.state = {
                user: null,
                loggedIn: true,
                waiting: true,
                selected_nav: 'dashboard',
                meta_visible: false,
            }
            this.onChangeNav = this.onChangeNav.bind(this)
            this.logout = this.logout.bind(this)
        }
    
        onChangeNav(selected_nav)
        {
            this.setState({selected_nav})
        }

        logout(e)
        {
            e.preventDefault();
            const {dispatch} = this.props
            dispatch(() => rootReducer(
                    {}, 
                    {
                        type: "USER_LOGOUT"
                    })
                )
            // history.push('/login')
            window.location = '/login'
        }

        componentDidMount(){
            this.mounted = true

            // socket.emit('message', "Hello World");
            // socket.on('message', function(msg){
            //     // alert("Hllo from server "+ msg)
            //     // console.log(msg)
            //   });

        }
        
        static getDerivedStateFromProps(props, state) {
            let update = {};
            if (props.user !== state.user) {
                update.user = props.user
            }
            if (props.loggedIn !== state.loggedIn) {
                update.loggedIn = props.loggedIn
            }

            if(props.permissions)
            {
                Ability.update(props.permissions)
            }

            update.waiting = false
            return Object.keys(update).length ? update : null;
        }


        render() {
            const {user, waiting, selected_nav} = this.state
            if(waiting)
            {
                return (<FullLoader />)
            }
            if(!waiting && isEmpty(user))
            {
                return(
                    <Redirect to="/login" />
                    )
            }

            return(
                <Fragment>
                    <div className="global-nav-wrapper">
                        <div className="global-nav">
                            <div className="global-brand-nav">
                                <Button className="logo" type="link" ghost>
                                    
                                    <img width="32px" height="36px" src="/assets/images/propto-icon.svg" title="Propto Internation" alt="Propto International"/>
                                </Button>
                                <Button type="link" ghost shape="circle">
                                    <Icon type="search" />
                                </Button>
                                <Button type="link" ghost shape="circle">
                                    <Icon type="plus" />
                                </Button>
                            </div>
                            <div className="global-user-nav">
                                <Button type="link" ghost>
                                    <Icon type="bell" />
                                </Button>
                                <Can I="delete" a="fb_connect">
                                    <Link  to="/settings" className="ant-btn ant-btn-link ant-btn-background-ghost">
                                        <Icon type="setting" />
                                    </Link>
                                </Can>
                                <Popover overlayClassName="meta-popover-wrapper" placement="rightBottom" trigger="click"
                                    content={
                                        <div className="meta-popover">
                                            <h4>{user.first_name + " "+ user.last_name}</h4>
                                            <Link to="/profile">View Profile</Link>
                                            <Link to="#" onClick={this.logout}>Logout</Link>
                                        </div>
                                    }
                                    >
                                    <Button type="link" ghost>
                                        <Avatar style={{ backgroundColor: 'rgba(0,0,0,0.4' }}>
                                            {Namify(user.first_name+" "+user.last_name)}
                                        </Avatar>
                                    </Button>
                                </Popover>
                            </div>
                        </div>
                        <div className="global-sidebar">
                            <ul>
                                <li className={selected_nav === "dashboard" ? 'selected' : ''}><Link to="/"><Icon type="dashboard" /> <span>Dashboard</span></Link></li>
                                {/* <Can I="read_own read" a="task">
                                    <li className={selected_nav === "tasks" ? 'selected' : ''}><Link to="/tasks"><Icon type="appstore" /><span>Tasks</span></Link></li>
                                </Can> */}
                                <Can I="read_own read" a="lead">
                                    <li className={selected_nav === "leads" ? 'selected' : ''}><Link to="/leads"><Icon type="funnel-plot" /><span>Leads</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="project">
                                    <li className={selected_nav === "projects" ? 'selected' : ''}><Link to="/projects"><Icon type="build" /><span>Projects</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="inventory">
                                    <li className={selected_nav === "inventory" ? 'selected' : ''}><Link to="/inventory"><Icon type="shop" /><span>Inventory</span></Link></li>
                                </Can>
                                <Can I="read" a="client">
                                    <li className={selected_nav === "clients" ? 'selected' : ''}><Link to="/clients"><Icon type="contacts" /><span>Clients</span></Link></li>
                                </Can>
                                <Can I={"read read_own"} a="team">
                                <li  className={selected_nav === "team" ? 'selected' : ''}>
                                    <Link to="/team"><Icon type="team" /><span>Team</span></Link>
                                    {/* <Can I="read_own read" a="access control">
                                    <ul>
                                    
                                        <li><Link to="/team/roles">Roles</Link></li>
                                    </ul>
                                    </Can> */}
                                </li>
                                </Can>
                                <Can I="read_own read" a="reports">
                                    <li className={selected_nav === "reports" ? 'selected' : ''}><Link to="/reports"><Icon type="line-chart" /><span>Reports</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="event">
                                    <li className={selected_nav === "events" ? 'selected' : ''}><Link to="/events"><Icon type="schedule" /><span>Events</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="account">
                                    <li className={selected_nav === "accounts" ? 'selected' : ''}><Link to="/accounts"><Icon type="book" /><span>Accounts</span></Link></li>
                                </Can>
                                {/* <Can I="read_own read" a="todo">
                                    <li className={selected_nav === "todo" ? 'selected' : ''}><Link to="/todo"><Icon type="file-done" /><span>Todo</span></Link></li>
                                </Can> */}
                                <Can I="read_own read" a="notes">
                                    <li className={selected_nav === "notes" ? 'selected' : ''}><Link to="/notes"><Icon type="read" /><span>Notes</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="announcement">
                                    <li className={selected_nav === "announcements" ? 'selected' : ''}><Link to="/announcements"><Icon type="notification" /><span>Announcements</span></Link></li>
                                </Can>
                                <Can I="read_own read" a="employee">
                                    <li className={selected_nav === "organization" ? 'selected' : ''}><Link to="/organization"><Icon type="apartment" /><span>Organization</span></Link></li>
                                </Can>
                                <li className={selected_nav === "calendar" ? 'selected' : ''}><Link to="/calendar"><Icon type="calendar" /><span>Calendar</span></Link></li>
                                <Can I="read_own read" a="roster">
                                    <li className={selected_nav === "roster" ? 'selected' : ''}><Link to="/roster"><Icon type="table" /><span>Roster</span></Link></li>
                                </Can>
                            </ul>
                        </div>
                        <div className="global-layout-contents inner-wrapper">
                            <ComposedComponent  activeNav={this.onChangeNav} loggedUser={user} {...this.props} />
                        </div>
                    </div>
                    
                </Fragment>
            )
        }
    }

    function mapStateToProps(state) {
        
        const { loggedIn, user, permissions } = state.auth;
        // Ability.update(permissions);
        return {
            loggedIn,
            user,
            permissions
        };
    }

    return connect(mapStateToProps)(DashboardLayout)
}