import {ReportConstants} from './../Actions/Types'
const reduceConstants = ReportConstants
const initialState = {
    stats: [],
    users: [],
};

export function ReportReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.REPORT:
            return {
                ...state,
                stats: action.payload,
            }

        case reduceConstants.USER_REPORTS:
            return {
                ...state,
                users: action.payload,
                // users: [],
            }

        case reduceConstants.FAILED:
            return {
                ...state
            }

        case reduceConstants.CLEAR:
            return {
                state
            }
        
        default:
            return state

    }
}