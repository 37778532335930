import {UserConstants} from './../Actions/Types'
const initialState = {
    all: [],
    teams:[],
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {
        
        case UserConstants.USERS:
            return {
                ...state,
                all: action.payload,
            }

        case UserConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case UserConstants.UPDATE:
            let index = state.all.findIndex(user => user.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }

        case UserConstants.TEAMS:
            return {
                ...state,
                teams: action.payload,
            }
        case UserConstants.ADD_TEAM:
            return {
                ...state,
                teams: [...state.teams, action.payload]
            }
        
        case UserConstants.UPDATE_TEAM:
            let team_index = state.teams.findIndex(team => team.id === action.payload.id);
            let teams = [...state.teams];
            teams[team_index] = action.payload;
            return {
                ...state,
                teams: teams
            }

        case UserConstants.FAILED:
            return {
                ...state
            }

        case UserConstants.CLEAR:
            return {}
        
        default:
            return state

    }
}