export const USER_LOGOUT = 'USER_LOGOUT'

export const RoleConstants = {
    ADD_ROLE: 'ADD_ROLE',
    REMOVE_ROLE: 'REMOVE_ROLE',
    ALL_ROLES: 'GET_ALL_ROLES',
    UPDATE_ROLE: 'UPDATE_ROLE',
    FAILED: 'ROLE_FAILED',
    CLEAR: 'ROLES_CLEAR',
}

export const ProjectConstants = {
    ADD: 'ADD_PROJECT',
    REMOVE: 'REMOVE_PROJECT',
    REMOVE_UNIT: 'REMOVE_UNIT',
    ALL: 'GET_ALL_PROJECTS',
    UPDATE: 'UPDATE_PROJECT',
    FAILED: 'PROJECT_FAILED',
    CLEAR: 'PROJECT_CLEAR',
    SINGLE: 'GET_PROJECT',
    UNIT_TYPES: 'GET_UNIT_TYPES',
    INVENTORY: 'GET_INVENTORY',
    PROJECT_INVENTORY: 'GET_PROJECT_INVENTORY',
    UPDATE_UNIT: 'UPDATE_PROJECT_UNIT',
    INVENTORY_PAGING: 'GET_INVENTORY_PAGING'
}


export const CityConstants = {
    ADD: 'ADD_CITY',
    REMOVE: 'REMOVE_CITY',
    ALL: 'GET_ALL_CITIES',
    FAILED: 'CITY_FAILED',
    CLEAR: 'CITY_CLEAR',
}

export const UserConstants = {
    ADD: 'ADD_USER',
    REMOVE: 'REMOVE_USER',
    USERS: 'GET_ALL_USERS',
    FAILED: 'USERS_FAILED',
    UPDATE: 'USER_UPDATE',
    CLEAR: 'USERS_CLEAR',
    TEAMS: 'GET_All_TEAMS',
    ADD_TEAM: 'ADD_TEAM',
    UPDATE_TEAM: 'UPDATE_TEAM',

}

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED'
}

export const ClientConstants = {
    ADD: 'ADD_CLIENT',
    REMOVE: 'REMOVE_CLIENT',
    ALL: 'GET_ALL_CLIENTS',
    UPDATE: 'UPDATE_CLIENT',
    FAILED: 'CLIENT_FAILED',
    CLEAR: 'CLIENT_CLEAR',
    SINGLE: 'GET_CLIENT'
}

export const LeadConstants = {
    ADD: 'ADD_LEAD',
    REMOVE: 'REMOVE_LEAD',
    ALL: 'GET_ALL_LEADS',
    UPDATE: 'UPDATE_LEAD',
    FAILED: 'LEAD_FAILED',
    CLEAR: 'LEADS_CLEAR',
    SINGLE: 'GET_LEAD',
    TASK_TYPES: 'GET_TASK_TYPES',
    IMPORT: 'IMPORT_LEADS'    
}

export const ReportConstants = {
    REPORT: 'GET_REPORT',
    CLEAR: 'REPORT_CLEAR',
    FAILED: 'REPORT_FAILED',
    USER_REPORTS: 'GET_USER_REPORTS'
}

export const EventConstants = {
    ADD: 'ADD_EVENT',
    REMOVE: 'REMOVE_EVENT',
    ALL: 'GET_ALL_EVENTS',
    UPDATE: 'UPDATE_EVENT',
    FAILED: 'EVENT_FAILED',
    CLEAR: 'EVENTS_CLEAR',
}

export const NotesConstants = {
    ADD: 'ADD_NOTE',
    REMOVE: 'REMOVE_NOTE',
    ALL: 'GET_ALL_NOTES',
    UPDATE: 'UPDATE_NOTE',
    FAILED: 'NOTES_FAILED',
    CLEAR: 'NOTES_CLEAR',
}

export const AnnouncementConstants = {
    ADD: 'ADD_ANNOUNCEMENT',
    REMOVE: 'REMOVE_ANNOUNCEMENT',
    ALL: 'GET_ALL_ANNOUNCEMENTS',
    UPDATE: 'UPDATE_ANNOUNCEMENT',
    FAILED: 'ANNOUNCEMENTS_FAILED',
    CLEAR: 'ANNOUNCEMENTS_CLEAR',
}

export const RosterConstants = {
    ADD: 'ADD_ROSTER',
    GET: 'GET_ROSTER',
    REMOVE: 'REMOVE_ROSTER',
    UPDATE: 'UPDATE_ROSTER',
    CLEAR: 'CLEAR_ROSTER',
    FAILED: 'ROASTER_FAILED'
}