import {RoleConstants} from './../Actions/Types'
const initialState = {
    all: [],
};

export function RoleReducer(state = initialState, action) {
    switch (action.type) {
        
        case RoleConstants.ALL_ROLES:
            return {
                ...state,
                all: action.payload,
            }

        case RoleConstants.ADD_ROLE:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case RoleConstants.UPDATE_ROLE:
            let index = state.all.findIndex(role => role.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }

        case RoleConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}