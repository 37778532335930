import {NotesConstants} from './../Actions/Types'
const reduceConstants = NotesConstants
const initialState = {
    all: [],
};

export function NotesReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload,
            }

        
        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE:
            let index = state.all.findIndex(note => note.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }


        case reduceConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}