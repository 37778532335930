import React from 'react';
import Spin from 'antd/lib/spin'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

// const antIcon = <Icon component={LogoSVG} style={{fontSize: '48px', color: 'red'}} spin  />;
export const FullLoader = () => (
    <div className="full-screen-loader">
        <Spin delay={0} size="large" />
        {/* <Spin delay={0}  indicator={antIcon} size="large" /> */}
    </div>
)

export const ComponentLoader = () => (
    <div className="component-loader">
        <Spin delay={0} />
    </div>
)

export const nFormat = (num, digits = 1) => {
    var si = [
                { value: 1, symbol: "" },
                { value: 1E3, symbol: "k" },
                { value: 1E6, symbol: "M" },
                { value: 1E9, symbol: "G" },
                { value: 1E12, symbol: "T" },
                { value: 1E15, symbol: "P" },
                { value: 1E18, symbol: "E" }
            ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
        break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const SmartLoader = () => (
    <svg width="2em" height="0.7em" fill="currentColor" viewBox="0 0 120 30">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
)

export const goToUrl = (props, url ) =>{
    const {history} = props
    return history.push(url);
}
export const handleResponse = (response) => {
    return response.text()
        .then((my_data) => {
            
            if(response.status === 404)
            {
                return Promise.reject('Error 404: Requested uri does not exist.')
            }
            const data = my_data && JSON.parse(my_data);
            if (!response.ok) {
                // throw Error(response.statusText);
                const error = (data && data.error) || response.statusText;
                return Promise.reject(error);
            }

            return data
        })
        .catch(error => {
            return Promise.reject(error);
        });



    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);
    //     if (!response.ok) {
    //         // if (response.status === 401) {
    //         //     // auto logout if 401 response returned from api
    //         //     // logout();
    //         //     //location.reload(true);
    //         // }
    //         const error = (data && data.error) || response.statusText;
    //         return Promise.reject(error);
    //     }
    //     return data;
    // });
}

export const LeadType = (type) => {
    
        switch(type) {
          case 'VERY COLD':
            return  <Tooltip  placement="left" title={'Very Cold'}>
                        <Icon type="meh" style={{marginLeft: '5px', marginRight: '15px', color: '#394989'}}/>
                    </Tooltip>;
          case 'COLD':
            return  <Tooltip  placement="left" title={'Cold'}>
                        <Icon type="meh" style={{marginLeft: '5px', marginRight: '15px', color: '#4594E8'}}/>
                    </Tooltip>;
          case 'MODERATE':
            return  <Tooltip  placement="left" title={'Moderate'}>
                        <Icon type="smile" style={{marginLeft: '5px', marginRight: '15px', color: '#47C04F'}}/>
                    </Tooltip>;
          case 'HOT':
            return  <Tooltip  placement="left" title={'Hot'}>
                        <Icon type="fire" style={{marginLeft: '5px', marginRight: '15px', color: '#F4BB00'}}/>
                    </Tooltip>;

          case 'VERY HOT':
            return  <Tooltip  placement="left" title={'Very Hot'}>
                        <Icon type="fire" style={{marginLeft: '5px', marginRight: '15px', color: '#E23F5A'}}/>
                    </Tooltip>;
          default:
            return null;
        }
}