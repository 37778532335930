import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { AppStore, persistor } from './Store/AppStore'
import { AbilityContext } from './Helpers/AbilityContext'
import Ability from './Helpers/Ability'
import Routes from './Routes'

export default class App extends Component {
    render() {
        return (
            <Provider store={AppStore}>
                <PersistGate loading={null} persistor={persistor}>
                    <AbilityContext.Provider value={Ability}>
                        <Routes />
                    </AbilityContext.Provider>
                </PersistGate>
            </Provider>
        );
    }
}

