import {CityConstants} from './../Actions/Types'
const reduceConstants = CityConstants
const initialState = {
    all: [],
};

export function CityReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload,
            }

        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE_ROLE:
        return {
            ...state,
            // all: [...state.all, action.payload]
        }

        case reduceConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}