import {AuthConstants} from './../Actions/Types'
import { TOKEN_NAME } from './../../Config'
import Ability from './../../Helpers/Ability'
let token = localStorage.getItem(TOKEN_NAME);

const initialState = {
    loggedIn: token ? true : false,
    token: token ? token : null,
    user: {},
    permissions: []
};

// const initialState = token ? { loggedIn: true, token: token  } : { loggingIn: false };

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        
        case AuthConstants.LOGIN:
            let abilities = [];
            let roles = action.payload.user.UserRoles;
            roles.map((role) => {
                let permissions = role.Role.RolePermissions;
                return  permissions.map((permission) => {
                            return abilities.push({
                                        subject: permission.Permission.Resource.resource,
                                        actions: [permission.Permission.permission],
                                        conditions: []
                                    })
                })
            })
            Ability.update(abilities);
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                permissions: abilities
            }

        default:
            return state

    }
}