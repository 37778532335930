import {ClientConstants} from './../Actions/Types'
const reduceConstants = ClientConstants
const initialState = {
    all: [],
    client:{},
    count: 0,
};

export function ClientReducer(state = initialState, action) {
    switch (action.type) {
        
        case reduceConstants.ALL:
            return {
                ...state,
                all: action.payload.rows,
                count: action.payload.count
            }

        case reduceConstants.SINGLE:
            return {
                ...state,
                client: action.payload,
            }


        case reduceConstants.ADD:
            return {
                ...state,
                all: [...state.all, action.payload]
            }

        case reduceConstants.UPDATE:
            let index = state.all.findIndex(client => client.id === action.payload.id);
            let all = [...state.all];
            all[index] = action.payload;
            return {
                ...state,
                all: all
            }

        case reduceConstants.FAILED:
            return {
                ...state
            }
        
        default:
            return state

    }
}