import React from 'react';

export const TOKEN_NAME = 'propto-realestate-token'
export const BASE_PATH = ''
// export const API_URL = 'http://localhost:5080/api/v1/'
export const API_URL = 'https://88websites.net/propto-api/api/v1/'
// export const API_URL = 'https://crm-mgc.com/mgc-api/api/v1/'
// export const API_URL = 'https://api.crm-mgc.com/api/v1/'

// export const API_URL = 'http://88websites.net/granapi/api/v1/'

// export const API_URL = 'http://194.28.84.212:5050/api/v1/'
// export const API_URL = 'http://23.239.220.217:5080/api/v1/'


export const LogoSVG = () => (   
        <svg width="1em" height="1em" viewBox="0 0 32 32">
            <g id="Group_1" data-name="Group 1" viewBox="0 0 32 32">
                    <path fill="#fff"  opacity="0.737" d="M20.83 18.268l-2.8 0.593c-0.319-1.572-1.178-2.41-2.86-2.178v2.364l-2.847-0.223v-0.391c0-4.479-0.005-8.958 0.007-13.437 0.003-0.039 0.005-0.084 0.005-0.129 0-0.525-0.238-0.994-0.611-1.306l-0.003-0.002c-0.337-0.294-0.644-0.595-0.933-0.914l-0.008-0.009c-0.067-0.070-0.131-0.217-0.1-0.286 0.066-0.076 0.163-0.124 0.271-0.124 0.003 0 0.006 0 0.009 0h-0c1.645-0.007 3.289-0.026 4.933 0 0.047-0.002 0.101-0.002 0.156-0.002 2.342 0 4.305 1.626 4.82 3.811l0.007 0.034c0.256 0.931 0.403 2 0.403 3.104 0 1.213-0.177 2.384-0.508 3.489l0.022-0.086c-0.343 1.258-1.151 2.287-2.224 2.912l-0.023 0.012c1.093 0.584 1.911 1.56 2.276 2.736l0.009 0.033zM15.19 4.025v10.768c0.238 0 0.456 0 0.674 0 0.008 0 0.017 0 0.026 0 0.8 0 1.482-0.504 1.746-1.212l0.004-0.013c0.163-0.367 0.297-0.797 0.383-1.244l0.006-0.038c0.16-0.95 0.252-2.045 0.252-3.162 0-0.575-0.024-1.143-0.072-1.706l0.005 0.073c-0.026-0.779-0.188-1.513-0.461-2.19l0.015 0.043c-0.224-0.623-0.752-1.087-1.4-1.217l-0.012-0.002c-0.372-0.068-0.757-0.067-1.165-0.1z"></path>
                    <path fill="#fff"  opacity="0.737" d="M10.031 15.449c0.44 0.425 0.879 0.842 1.309 1.269 0.154 0.156 0.249 0.371 0.249 0.608 0 0.032-0.002 0.064-0.005 0.096l0-0.004c-0.022 0.4-0.006 0.8-0.006 1.244-0.33-0.072-0.624-0.132-0.917-0.2-0.251-0.059-0.5-0.136-0.75-0.189-0.46-0.075-0.845-0.345-1.074-0.719l-0.004-0.007c-0.386-0.568-1.029-0.936-1.757-0.936-0.182 0-0.358 0.023-0.526 0.066l0.015-0.003-0.023 0.323c-0.172-0.082-0.33-0.144-0.474-0.229-0.714-0.424-1.422-0.858-2.137-1.282-0.134-0.073-0.223-0.213-0.223-0.374 0-0.017 0.001-0.033 0.003-0.049l-0 0.002q0.009-4.942 0-9.885c0-0.2-0.007-0.4-0.016-0.6-0.010-0.321-0.151-0.608-0.371-0.809l-0.001-0.001q-0.6-0.573-1.186-1.165c-0.052-0.054-0.084-0.127-0.084-0.208 0-0.012 0.001-0.024 0.002-0.036l-0 0.001c0.045-0.076 0.124-0.127 0.215-0.135l0.001-0c1.892 0.010 3.787-0.026 5.676 0.067 1.341 0.049 2.527 0.676 3.322 1.637l0.006 0.008c0.187 0.216 0.3 0.499 0.3 0.81 0 0.011-0 0.021-0 0.032l0-0.002q0 4.441 0 8.882c-0.009 0.128-0.052 0.245-0.119 0.343l0.002-0.002c-0.374 0.583-0.85 1.066-1.408 1.436l-0.018 0.011zM6.565 14.794c0.24 0 0.458 0 0.676 0 0.006 0 0.014 0 0.021 0 0.821 0 1.518-0.531 1.767-1.268l0.004-0.013c0.132-0.317 0.248-0.692 0.327-1.081l0.007-0.040c0.17-0.931 0.267-2.002 0.267-3.096 0-0.467-0.018-0.929-0.052-1.387l0.004 0.061c-0.036-0.837-0.162-1.628-0.369-2.386l0.018 0.076c-0.216-0.955-1.057-1.658-2.063-1.658-0.216 0-0.425 0.032-0.621 0.093l0.015-0.004z"></path>
                    <path fill="#fff"  d="M18.135 19.257l2.831-0.617c0.083 0.46 0.2 0.931 0.247 1.409 0.078 0.592 0.123 1.276 0.123 1.971 0 1.4-0.181 2.758-0.52 4.051l0.025-0.111c-0.181 0.643-0.417 1.204-0.713 1.726l0.020-0.038c-0.628 1.206-1.869 2.016-3.299 2.016-0.026 0-0.051-0-0.076-0.001l0.004 0c-1.931 0.023-3.863 0.009-5.795 0-0.119-0.015-0.223-0.070-0.3-0.15l-0-0c-0.023-0.022 0.053-0.185 0.117-0.252 0.325-0.337 0.643-0.684 1-0.988 0.34-0.29 0.554-0.719 0.554-1.198 0-0.033-0.001-0.065-0.003-0.098l0 0.004c-0.007-2.455 0-4.91 0-7.364v-0.35l2.816 0.2v8.4c0.599-0.006 1.182-0.044 1.756-0.113l-0.075 0.007c0.499-0.099 0.884-0.491 0.969-0.987l0.001-0.007c0.174-0.667 0.312-1.463 0.385-2.277l0.004-0.058c0.059-0.668 0.092-1.445 0.092-2.23 0-1.038-0.058-2.063-0.172-3.071l0.011 0.124z"></path>
                    <path fill="#fff"  opacity="0.737" d="M24.378 16.882c-0.037-0.043-0.073-0.090-0.105-0.14l-0.003-0.005c-1.042-1.978-1.914-4.274-2.498-6.683l-0.043-0.207c-0.208-0.835-0.327-1.794-0.327-2.781 0-0.756 0.070-1.495 0.204-2.212l-0.012 0.074c0.080-0.37 0.192-0.696 0.336-1.003l-0.012 0.028c0.474-1.042 1.506-1.754 2.704-1.754 0.467 0 0.908 0.108 1.3 0.3l-0.017-0.008c0.331 0.161 0.613 0.331 0.878 0.523l-0.017-0.012c0.506 0.345 0.693 0.347 1.166-0.046 0.2-0.189 0.414-0.37 0.637-0.54l0.019-0.014c0.115-0.087 0.259-0.139 0.416-0.139 0.034 0 0.068 0.002 0.1 0.007l-0.004-0c0.1 0.036 0.164 0.3 0.165 0.469 0.011 2.045 0.007 4.089 0.007 6.134 0 0.87 0 1.739 0 2.608 0 0.166 0.048 0.386-0.2 0.4-0.23 0.014-0.251-0.2-0.285-0.367-0.286-1.453-0.691-2.735-1.22-3.95l0.047 0.122c-0.453-0.997-0.885-1.812-1.358-2.599l0.064 0.115c-0.215-0.325-0.47-0.602-0.762-0.834l-0.007-0.006c-0.195-0.194-0.464-0.314-0.761-0.314-0.159 0-0.31 0.034-0.446 0.096l0.007-0.003c-0.423 0.187-0.488 0.591-0.518 0.986-0.016 0.196-0.025 0.424-0.025 0.654 0 0.787 0.107 1.549 0.307 2.273l-0.014-0.060c0.613 2.033 1.297 3.757 2.107 5.408l-0.092-0.208c0.266 0.593 0.544 1.18 0.818 1.768 0.054 0.117 0.113 0.217-0.041 0.315-0.841 0.534-1.676 1.070-2.516 1.605z"></path>
                    <path fill="#fff"  d="M24.506 17.224l2.691-1.668c0.073 0.155 0.156 0.322 0.234 0.492 0.719 1.387 1.398 3.030 1.938 4.734l0.062 0.228c0.276 0.964 0.435 2.072 0.435 3.216 0 0.84-0.086 1.66-0.248 2.452l0.013-0.078c-0.135 0.675-0.407 1.271-0.787 1.782l0.008-0.012c-0.541 0.793-1.441 1.307-2.46 1.307-0.587 0-1.135-0.171-1.596-0.465l0.012 0.007c-0.29-0.166-0.574-0.344-0.859-0.518-0.068-0.048-0.152-0.077-0.243-0.077-0.11 0-0.211 0.042-0.286 0.111l0-0c-0.34 0.263-0.68 0.527-1.034 0.771-0.118 0.061-0.255 0.109-0.399 0.137l-0.010 0.002c-0.040-0.106-0.071-0.23-0.086-0.359l-0.001-0.008q-0.007-4.413 0-8.826c0-0.159-0.073-0.4 0.166-0.414 0.082-0.005 0.223 0.227 0.265 0.371 0.417 1.438 0.769 2.9 1.244 4.315 0.311 0.807 0.673 1.501 1.101 2.148l-0.025-0.041c0.279 0.484 0.739 0.837 1.286 0.969l0.014 0.003c0.084 0.028 0.18 0.045 0.281 0.045 0.376 0 0.697-0.23 0.832-0.557l0.002-0.006c0.172-0.392 0.272-0.85 0.272-1.331 0-0.133-0.008-0.265-0.023-0.394l0.001 0.016c-0.081-1.392-0.39-2.691-0.891-3.889l0.029 0.078c-0.529-1.35-1.125-2.674-1.693-4.009-0.079-0.184-0.167-0.362-0.246-0.532z"></path>
                    <path fill="#fff"  d="M3.726 15.965c0.923 0.508 1.819 1 2.7 1.5 0.075 0.043 0.114 0.2 0.115 0.311 0.007 1.976 0 3.953 0.005 5.929 0 1.136 0.008 2.273 0 3.409-0.002 0.030-0.003 0.065-0.003 0.101 0 0.456 0.205 0.865 0.527 1.139l0.002 0.002c0.356 0.3 0.685 0.639 1.010 0.974 0.069 0.073 0.111 0.171 0.111 0.279 0 0.013-0.001 0.026-0.002 0.038l0-0.002c-0.013 0.060-0.183 0.121-0.283 0.121q-2.767 0.011-5.534 0c-0.106 0-0.271-0.063-0.3-0.139s0.048-0.231 0.123-0.308c0.369-0.374 0.749-0.737 1.132-1.1 0.216-0.194 0.356-0.469 0.373-0.777l0-0.003c0.012-0.19 0.024-0.381 0.024-0.572q0-5.285 0-10.569z"></path>
                    <path fill="#fff"  d="M10.217 29.194c-0.479-0.439-0.778-1.067-0.778-1.765 0-0.039 0.001-0.077 0.003-0.115l-0 0.005c-0.005-2.549 0-5.1 0-7.646 0-0.349-0.032-0.7-0.049-1.049 0.69 0.157 1.378 0.307 2.061 0.477 0.056 0.014 0.111 0.16 0.113 0.245 0.010 0.6 0.005 1.2 0.005 1.8 0 1.919-0.012 3.837 0.007 5.756 0.002 0.034 0.004 0.074 0.004 0.115 0 0.53-0.239 1.004-0.614 1.321l-0.003 0.002c-0.265 0.261-0.51 0.539-0.734 0.835l-0.014 0.019z"></path>
            </g>
        </svg>
)

export const loaderSVG = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 120 30">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
)

export const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

export const Namify = (name) => {
    if(name)
    {
        let matches = name.match(/\b(\w)/g); // ['J','S','O','N']
        if(matches){
            matches.length = 2;
            return matches.join('').toUpperCase(); // JSON
        }
        else
        {
            return 'N/A';
        }        
    }
    return '';
}



