import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { RoleReducer } from './RoleReducer';
import { UserReducer } from './UserReducer';
import { ProjectReducer } from './ProjectReducer';
import { CityReducer } from './CityReducer';
import { ClientReducer } from './ClientReducer';
import { LeadReducer } from './LeadReducer';
import { ReportReducer } from './ReportReducer';
import { EventReducer } from './EventReducer';
import { NotesReducer } from './NotesReducer';
import { AnnouncementReducer } from './AnnouncementReducer';
import { RosterReducer } from './RosterReducer';


export default combineReducers({
    auth: AuthReducer,
    roles: RoleReducer,
    users: UserReducer,
    projects: ProjectReducer,
    cities: CityReducer,
    clients: ClientReducer,
    leads: LeadReducer,
    report: ReportReducer,
    events: EventReducer,
    notes: NotesReducer,
    announcements: AnnouncementReducer,
    roster: RosterReducer
});